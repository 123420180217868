
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes } from '@/store/auth/actions'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { authTypes } from '@shared/'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import Header from '@/components/organisms/Header.vue'
export default defineComponent({
  name: 'Login',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const schema = yup.object({
      email: yup.string().required().email().label('Email'),
      password: yup.string().required().label('Password')
    })

    const initialData: authTypes.AuthBody = {
      email: '',
      password: ''
    }

    const login = async (values: authTypes.AuthBody) => {
      await store.dispatch(ActionTypes.LOGIN, { email: values.email, password: values.password })
    }

    const message = route.query.message ? 'Your account was successfully validated' : ''

    return { login, message, schema, initialData }
  }
})
