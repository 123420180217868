<template>
  <Header headerClass="transparent"></Header>
  <div class="login-page">
    <Form class="login" v-slot="{ values }" @submit="login" :validation-schema="schema">
      <FormWrapper>
        <template #form-head>
          <h2>Sign in</h2>
          <p>Lets get you started</p>
          {{ message }}
        </template>
        <TextInput name="email" type="email" placeholderText="Email" testId="email" />
        <TextInput name="password" type="password" placeholderText="Password" testId="password" />
        <template #form-submit>
          <CustomButton type="submit" color="primary" label="Next" testId="login" />
        </template>
      </FormWrapper>
      <div class="link-line">
        <p>
          Forgotten your password?
          <router-link :to="{ name: 'ResetPassword', query: { email: values.email } }">Reset</router-link>
        </p>
      </div>
    </Form>
    <div class="link-line absolute">
      <p>I’m new to Studiospace <router-link :to="{ name: 'Register' }">Sign up</router-link></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import { ActionTypes } from '@/store/auth/actions'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { authTypes } from '@shared/'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import * as yup from 'yup'
import Header from '@/components/organisms/Header.vue'
export default defineComponent({
  name: 'Login',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    Header
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const schema = yup.object({
      email: yup.string().required().email().label('Email'),
      password: yup.string().required().label('Password')
    })

    const initialData: authTypes.AuthBody = {
      email: '',
      password: ''
    }

    const login = async (values: authTypes.AuthBody) => {
      await store.dispatch(ActionTypes.LOGIN, { email: values.email, password: values.password })
    }

    const message = route.query.message ? 'Your account was successfully validated' : ''

    return { login, message, schema, initialData }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.login-page
  flex: 1
  margin: 2rem
  .login
    margin: 0 auto
.link-line
  display: flex
  align-self: center
  align-items: center
  justify-content: center
  a
    margin-left: 2px
    text-decoration: none
    font-weight: bold
    color: $purple
.absolute
  bottom: 0
  left: 0
  right: 0
  position: fixed
</style>
